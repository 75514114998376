<template>
    <categories-module-component/>
</template>

<script>
    import CategoriesModuleComponent from "@/components/admin/CategoriesModuleComponent";

    export default {
        name: "CategoriesModule",
        title: "Categorías | Private",
        components: {CategoriesModuleComponent}
    }
</script>

<style scoped>

</style>